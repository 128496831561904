// extracted by mini-css-extract-plugin
export var borderBG = "style-module--borderBG--A3PF7";
export var main = "style-module--main--djB5I";
export var top = "style-module--top--h7Pkk";
export var dateWrapper = "style-module--dateWrapper--RWaqi";
export var dateStyle = "style-module--dateStyle--UsU7a";
export var more = "style-module--more--znzHK";
export var articleWrapper = "style-module--articleWrapper--Vyiw-";
export var content = "style-module--content--mKOcY";
export var title = "style-module--title--XXKjN";
export var titleStyle = "style-module--titleStyle--Of8hP";
export var descStyle = "style-module--descStyle--idfMs";
export var author = "style-module--author--0Te7s";
export var authorStyle = "style-module--authorStyle--j2EU6";
export var image = "style-module--image--1C3wt";
export var imageCover = "style-module--imageCover--m9vWE";